<template>
  <v-container
      :class="ShowMainPage === 'Y' ? 'pa-0 custom-bg' : 'pa-0'"
      fluid>
    <v-container
      id="about"
      tag="section"
    >
      <v-row>
        <v-col cols="12" :align="[ShowImg!=='N' ? 'center' : 'start']" class="ml-5 pa-0">
          <v-card flat max-width="1000">
            <v-row :align="[ShowImg!=='N' ? 'center' : 'start']">
              <v-col cols="12" align="start" class="mb-0 pb-0">
                <span class="display-3"><strong>{{ $t('MeeTeeMeeNgern1.aboutUsHeader') }}</strong></span>
                <v-divider></v-divider>
              </v-col>
              <v-img v-if="ShowImg!=='N'"
                :src="require(`@/assets/MeeTeeMeeNgern/1000x420px/about_1000x420.jpg`)"
                height="100%">
              </v-img>
            <!-- <base-subheading><span class="display-3">{{ $t('MeeTeeMeeNgern1.aboutUsHeader') }}</span></base-subheading> -->
              <v-col cols="12" align="start" class="mb-0 pt-5">
                <p>
                  <span class="display-2">
                    {{ $t('MeeTeeMeeNgern1.aboutUs1') }}
                  </span>
                </p>
                <p>
                  <span class="display-2">
                    {{ $t('MeeTeeMeeNgern1.aboutUs2') }}
                  </span>
                </p>
                <p>
                  <span class="display-2">
                    {{ $t('MeeTeeMeeNgern1.aboutUs3') }}
                  </span>
                </p>
                <p>
                  <span class="display-2">
                    {{ $t('MeeTeeMeeNgern1.aboutUs4') }}
                  </span>
                </p>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
  export default {
    name: 'HomeAbout',
    props: {
      ShowImg: {
        type: String,
        required: true,
      },
      ShowMainPage: {
        type: String,
        required: false,
      },
    },
  }
</script>
<style scoped>
  .custom-bg {
    background: rgb(242, 245, 247) !important;
    /* background: url('../../assets/bg/bg1.jpg');
    background-size: cover; */
  }
</style>